<template>
  <itemCard type="primary" :data="computedData" :action="action" :actionText="actionText" />
</template>

<script>
import listItem from "@/components/liffList/listItem.js";
import orderCardItemMixins from "@/mixins/orderCardItem.js"
export default {
  mixins: [listItem],
  components: {
    itemCard: () => import("@/modules/base/components/applyPay/applyPayItemCardStore.vue"),
  },
  computed: {
    providerId() {
      return this.$route.params.providerId
    },
    computedData() {
      return this.data;
    },
    count() {
      return this.data.count
    },
    actionText() {
      return `共${this.count}筆`
    },
  },
  methods: {
    action() {
      this.$router.push({
        name: "store-apply-pay-detail",
        params: {
          providerId: this.providerId,
          routeProviderId: this.data.provider_id,
          id: this.data.apply_pay_id
        },
      });
    },
  },
};
</script>